import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "./api";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

const EditLocation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    state: "",
    cities: [],
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    fetchLocationDetails();
    fetchStates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLocationDetails = async () => {
    try {
      const res = await api.get(`/user/location/${id}`);
      const { response } = res.data;
      const { state, cities } = response;

      const citiesArray = cities.map((city) => ({ value: city, label: city }));

      setFormData({ state, cities });
      setSelectedCities(citiesArray);
      fetchCities(state);
    } catch (error) {
      console.error("Error fetching location details:", error);
      setErrorMessage("Failed to fetch location details. Please try again later.");
      setErrorModalOpen(true);
    }
  };

  const fetchStates = async () => {
    try {
      const res = await api.get("/user/states");
      const { response } = res.data;
      const stateOptions = response.map((state) => ({ value: state, label: state }));
      setStateOptions(stateOptions);
    } catch (error) {
      console.error("Error fetching states:", error);
      setErrorMessage("Failed to fetch states. Please try again later.");
      setErrorModalOpen(true);
    }
  };

  const fetchCities = async (state) => {
    try {
      const res = await api.get(`/user/getCitiesByState/${state}`);
      const { response } = res.data;
      const cities = response.map((city) => ({ value: city, label: city }));
      setCityOptions(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setErrorMessage("Failed to fetch cities. Please select another state or try again later.");
      setErrorModalOpen(true);
    }
  };

  const handleStateChange = (selectedOption) => {
    const state = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, state: state, cities: [] });
    setSelectedCities([]);
    fetchCities(state);
    setIsSaveEnabled(true);
  };

  const handleCityChange = (selectedOptions) => {
    const cities = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setSelectedCities(selectedOptions);
    setFormData({ ...formData, cities: cities });
    setIsSaveEnabled(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.state || formData.cities.length === 0) {
      setErrorMessage("Please select a state and at least one city.");
      setErrorModalOpen(true);
      return;
    }

    try {
      await api.put(`/user/location/${id}`, formData);
      navigate("/location");
    } catch (error) {
      console.error("Error updating location:", error);
      setErrorMessage("Failed to update location. Please try again later.");
      setErrorModalOpen(true);
    }
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  return (
    <div className="bg-emerald-50 min-h-screen flex flex-col">
      <div className="container mx-auto px-4 py-8 bg-emerald-50">
        {errorModalOpen && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 shadow-md max-w-sm w-full">
              <div className="text-center mb-4">
                <h2 className="text-lg font-semibold text-red-600">Error</h2>
              </div>
              <p className="text-sm text-gray-700 text-center">{errorMessage}</p>
              <div className="mt-4 text-center">
                <button onClick={closeErrorModal} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500">
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-8 mx-4 md:mx-16">
          <h2 className="text-2xl font-bold mb-4 text-center">EDIT LOCATION</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                State
              </label>
              <Select
                id="state"
                name="state"
                value={stateOptions.find((option) => option.value === formData.state)}
                onChange={handleStateChange}
                options={stateOptions}
                isDisabled={true}
                className="mt-1 block w-full border-gray-300 py-2 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="cities" className="block text-sm font-medium text-gray-700">
                Cities
              </label>
              <MultiSelect
                id="cities"
                name="cities"
                options={cityOptions}
                value={selectedCities}
                onChange={handleCityChange}
                labelledBy="Select cities"
                className="mt-1 block w-full border-gray-300 py-2 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <button
              type="submit"
              className={`bg-sky-500 text-white px-4 py-2 rounded-md bg-color-hover transition-colors duration-300 ${isSaveEnabled ? "" : "cursor-not-allowed opacity-50"}`}
              disabled={!isSaveEnabled}>
              Save
            </button>
            <button type="button" onClick={() => navigate("/location")} className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition-colors duration-300">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditLocation;
