import axios from "axios";
import { redirectTo } from "./navigation";
import { clearStorage } from "../service/storageService";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json", // change according header type accordingly
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // get stored access token
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // set in header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      const email = localStorage.getItem("email");
      if (refreshToken) {
        try {
          const res = await axios.post(`${baseURL}/user/refresh`, { email, refreshToken });
          // don't use axious instance that already configured for refresh token api call
          const { response } = res.data;
          const newAccessToken = response.jwtToken;
          localStorage.setItem("accessToken", newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (error) {
          clearStorage();
          redirectTo("/login");
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
