import { BeatLoader } from "react-spinners";

const Loader = () => (
  <div className="absolute w-full h-full grid content-center justify-items-center backdrop-blur z-10">
    <div>
      <BeatLoader color="#00BAD2" loading={true} aria-label="Loading Spinner" data-testid="loader" />
    </div>
  </div>
);

export default Loader;
