import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import backgroundImage from "../assets/images/header_background.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [username, setUsername] = useState("");
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) return;
      const user = jwtDecode(token);
      if (user && user.fname) setUsername(user.fname);
    };
    getUserDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="text-white p-4 shadow-md flex justify-between items-center bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div>
        <p className="text-2xl">Good Morning, {username}</p>
      </div>
      <FontAwesomeIcon icon={faBars} className="text-white cursor-pointer lg:hidden" onClick={toggleMenu} />
      {menuOpen && (
        <ul ref={menuRef} className="absolute top-16 right-4 bg-white text-sky-700 font-bold py-2 px-4 rounded-lg shadow-md z-10">
          <li className="my-2">
            <NavLink to="/dashboard" className="block py-2" onClick={toggleMenu}>
              Dashboard
            </NavLink>
          </li>
          <li className="my-2">
            <NavLink to="/user" className="block py-2" onClick={toggleMenu}>
              User
            </NavLink>
          </li>
          <li className="my-2">
            <NavLink to="/location" className="block py-2" onClick={toggleMenu}>
              Location
            </NavLink>
          </li>
          <li className="my-2">
            <NavLink to="/logout" className="block py-2" onClick={toggleMenu}>
              Logout
            </NavLink>
          </li>
        </ul>
      )}
      <nav className="hidden lg:flex space-x-4">
        <NavLink to="/dashboard" className={({ isActive }) => "py-2 px-4 bg-color-dark-hover rounded-md " + (isActive ? "bg-color-dark" : "")}>
          Dashboard
        </NavLink>
        <NavLink to="/user" className={({ isActive }) => "py-2 px-4 bg-color-dark-hover rounded-md " + (isActive ? "bg-color-dark" : "")}>
          User
        </NavLink>
        <NavLink to="/location" className={({ isActive }) => "py-2 px-4  rounded-md bg-color-dark-hover " + (isActive ? "bg-color-dark" : "")}>
          Location
        </NavLink>
        <NavLink to="/logout" className="py-2 px-4  rounded-md">
          Logout
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
