import React, { useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineMail, HiOutlineLockClosed } from "react-icons/hi";
import backgroundImage from "../assets/images/background.svg";
import logo_black from "../assets/images/logo_black.svg";
import { useAuth } from "../utils/authContext";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../service/storageService";

const LoginPage = () => {
  const { login, setLoading } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    clearStorage();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const params = { email: email.toLowerCase(), password, userType: "admin" };
      const { data, status } = await axios.post(`${baseURL}/user/authenticate`, params);
      if (status === 200) {
        login({ email: email.toLowerCase(), ...data.response });
        navigate("/dashboard");
        setLoading(false);
      } else {
        setLoading(false);
        setError(data.response || "Invalid email or password");
      }
    } catch (error) {
      setLoading(false);
      setError((error.response && error.response.data.response) || "An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex items-center justify-end min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})`, overflow: "hidden" }}>
      <div className="w-full max-w-md p-8 mr-8 space-y-6 bg-black bg-opacity-75 rounded-lg shadow-lg">
        <img src={logo_black} alt="company logo" />
        <form className="space-y-6" onSubmit={handleSubmit}>
          {error && <div className="p-3 mb-4 text-center text-red-500 bg-red-100 rounded-md">{error}</div>}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <HiOutlineMail className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
            <input
              type="email"
              id="email"
              placeholder="Email Id"
              aria-label="Email Id"
              className="w-full p-3 pl-10 text-white bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <HiOutlineLockClosed className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
            <input
              type="password"
              id="password"
              placeholder="Password"
              aria-label="Password"
              className="w-full p-3 pl-10 text-white bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full py-2 text-md text-white bg-color rounded-md transition duration-300">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
