import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./page/LoginPage";
import DashboardHomePage from "./page/DashboardHomePage";
import FormData from "./page/UserPage";
import Location from "./page/LocationPage";
import AddUserForm from "./utils/AddUserForm";
import AddUserLocation from "./utils/AddUserLocation";
import NotFound from "./page/NotFoundPage";
import "./index.css";
import "./assets/css/Common.css";
import EditUser from "./utils/EditUser";
import EditLocation from "./utils/EditLocation";
import PrivateRoute from "./components/PrivateRoute";
import Logout from "./page/Logout";
import { AuthProvider, useAuth } from "./utils/authContext";
import { NavigateSetter } from "./utils/navigation";
import Loader from "./utils/Loader";

const AppWrapper = () => {
  const { loading } = useAuth();
  return (
    <div className="app-container">
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<PrivateRoute component={DashboardHomePage} />} />
        <Route path="/add-user" element={<PrivateRoute component={AddUserForm} />} />
        <Route path="/user" element={<PrivateRoute component={FormData} />} />
        <Route path="/edit-user/:id" element={<PrivateRoute component={EditUser} />} />
        <Route path="/edit-location/:id" element={<PrivateRoute component={EditLocation} />} />
        <Route path="/location" element={<PrivateRoute component={Location} />} />
        <Route path="/add-location" element={<PrivateRoute component={AddUserLocation} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
};

const App = () => (
  <AuthProvider>
    <Router>
      <NavigateSetter />
      <AppWrapper />
    </Router>
  </AuthProvider>
);

export default App;
