// src/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { redirectTo } from "./navigation";
import { clearStorage } from "../service/storageService";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      logout();
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  const login = ({ email, jwtToken, refreshToken, userType = "admin" }) => {
    // Set authentication token here
    localStorage.setItem("accessToken", jwtToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("email", email);
    localStorage.setItem("userType", userType);
    setIsAuthenticated(true);
  };

  const logout = () => {
    clearStorage()
    setIsAuthenticated(false);
    redirectTo("/login");
  };

  return <AuthContext.Provider value={{ loading, setLoading, isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};
