import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

const LocationPage = () => {
  const [filter, setFilter] = useState("");
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [expandedDetailsIndex, setExpandedDetailsIndex] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.get("/user/locations");

      const locationsData = data.response.map((location) => ({
        id: location.id,
        state: location.state,
        cities: location.cities.join(", "),
      }));

      setLocations(locationsData);
      setFilteredLocations(locationsData);
      setCurrentPage(1);
      setExpandedDetailsIndex(-1);

      const pages = Math.ceil(locationsData.length / itemsPerPage);
      setTotalPages(pages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const pages = Math.ceil(filteredLocations.length / itemsPerPage);
    setTotalPages(pages);
  }, [filteredLocations, itemsPerPage]);

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleSearch = async () => {
    try {
      const filtered = locations.filter((location) => location.state.toLowerCase().includes(filter.toLowerCase()) || location.cities.toLowerCase().includes(filter.toLowerCase()));

      setFilteredLocations(filtered);
      setCurrentPage(1);
      setExpandedDetailsIndex(-1);
    } catch (error) {
      console.error("Error searching locations:", error);
    }
  };

  const handleClear = () => {
    setFilter("");
    setFilteredLocations(locations);
    setCurrentPage(1);
    setExpandedDetailsIndex(-1);
  };

  const handleToggleDetails = (index) => {
    setExpandedDetailsIndex(index === expandedDetailsIndex ? -1 : index);
  };

  const handleEdit = (id) => {
    navigate(`/edit-location/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/user/location/${id}`);

      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting location:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLocations.slice(indexOfFirstItem, indexOfLastItem);

  const paginationButtons = [];
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, totalPages); i++) {
    paginationButtons.push(
      <button key={i} onClick={() => handlePageChange(i)} className={`bg-color text-white px-4 py-2 rounded-md mr-2 ${i === currentPage ? "bg-color-dark" : "bg-color-dark-hover"}`}>
        {i}
      </button>
    );
  }

  return (
    <div className="bg-emerald-50 w-full mx-auto min-h-screen flex flex-col">
      <div className="container w-full mx-auto px-4 py-8">
        <div className="filter-section bg-white rounded-lg shadow-md mb-8 p-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <div className="flex items-center justify-center space-x-2">
              <label htmlFor="filter" className="block text-sm font-medium text-gray-700">
                Search:
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="filter"
                  placeholder="Enter state or city"
                  value={filter}
                  onChange={(e) => handleFilterChange(e.target.value)}
                  className="mt-1 block border border-gray-300 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  style={{ width: "200px" }}
                />
                {filter && (
                  <button type="button" onClick={handleClear} className="absolute inset-y-0 right-0 px-3 py-2 text-gray-400 hover:text-gray-600">
                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
              </div>
              <button
                type="button"
                className={`bg-sky-500 text-white px-4 py-2 rounded-md bg-color-hover transition-colors duration-300 ${!filter ? "cursor-not-allowed opacity-50" : ""}`}
                onClick={handleSearch}
                disabled={!filter}>
                Search
              </button>
              <button
                type="button"
                className={`bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors duration-300  ${!filter ? "cursor-not-allowed opacity-50" : ""}`}
                onClick={handleClear}
                disabled={!filter}>
                Clear
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-end mt-8">
          <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors duration-300" onClick={() => navigate("/add-location")}>
            Add Location
          </button>
        </div>
        <div className="data-table-section bg-white rounded-lg shadow-md p-4 mt-4">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2">State</th>
                <th className="py-2">Cities</th>
                <th className="py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((location, index) => (
                <React.Fragment key={location.id}>
                  <tr className="text-center border-b-2">
                    <td className="py-4">{location.state}</td>
                    <td className="py-4">{location.cities}</td>
                    <td className="py-4">
                      <FontAwesomeIcon icon={faPencilAlt} onClick={() => handleEdit(location.id)} className="cursor-pointer text-sky-500 mr-2" />
                      <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(location.id)} className="cursor-pointer text-red-500" />
                    </td>
                    <td className="py-4">
                      <span onClick={() => handleToggleDetails(index)} className="absolute transform translate-y-1/2 left-1/2 -ml-4 cursor-pointer" style={{ marginTop: "3px" }}>
                        <FontAwesomeIcon icon={expandedDetailsIndex === index ? faChevronUp : faChevronDown} />
                      </span>
                    </td>
                  </tr>
                  {expandedDetailsIndex === index && (
                    <tr>
                      <td colSpan="4" className="border-b border-gray-300 px-4 py-2 bg-slate-100">
                        <div>
                          <p>
                            <strong>State:</strong> {location.state}
                          </p>
                          <p>
                            <strong>Cities:</strong> {location.cities}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between items-center mt-4">
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">{paginationButtons}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPage;
