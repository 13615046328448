import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-red-500 mb-4">404</h1>
      <p className="text-xl text-gray-700 mb-8">Oops! The page you're looking for doesn't exist.</p>
      <NavLink to="/" className="bg-sky-500 text-white px-4 py-2 rounded-md bg-color-hover transition-colors duration-300">
        Go Back Home
      </NavLink>
    </div>
  );
};

export default NotFoundPage;
