import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import api from "./api";

const ErrorModal = ({ message, onClose }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 shadow-md max-w-sm w-full">
        <div className="text-center mb-4">
          <h2 className="text-lg font-semibold text-red-600">Error</h2>
        </div>
        <p className="text-sm text-gray-700 text-center">{message}</p>
        <div className="mt-4 text-center">
          <button onClick={onClose} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const AddUserForm = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    userType: "",
    email: "",
    password: "",
    confirmPassword: "",
    state: "",
    cities: [],
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [stateToCitiesMap, setStateToCitiesMap] = useState({});

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await api.get("/user/locations");
      const stateMap = {};
      const stateOptions = [];
      const locations = response.data.response;
      locations.forEach((item) => {
        if (!stateMap[item.state]) {
          stateOptions.push({ value: item.state, label: item.state });
          if (Array.isArray(item.cities)) {
            stateMap[item.state] = item.cities.map((city) => ({ value: city, label: city }));
          } else if (typeof item.cities === "string") {
            stateMap[item.state] = item.cities.split(", ").map((city) => ({ value: city, label: city }));
          }
        }
      });

      setStateOptions(stateOptions);
      setStateToCitiesMap(stateMap);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleStateChange = (selectedOption) => {
    const state = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, state: state, cities: [] });
    setSelectedCities([]);
    setCityOptions(stateToCitiesMap[state] || []);
    setErrors({ ...errors, state: "", cities: "" });
    setIsSaveEnabled(true);
  };

  const handleCityChange = (selectedOptions) => {
    const cities = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setSelectedCities(selectedOptions);
    setFormData({ ...formData, cities: cities });
    setErrors({ ...errors, cities: "" });
    setIsSaveEnabled(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    checkErrors(name, value);
  };

  const validate = (event) => {
    const { name, value } = event.target;
    checkErrors(name, value);
  };

  const checkErrors = (name, value) => {
    if (!value) {
      setErrors((prev) => ({ ...prev, [name]: `${getFieldLabel(name)} is required` }));
    } else {
      switch (name) {
        case "email":
          const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          setErrors((prev) => ({ ...prev, [name]: !re.test(String(formData.email).toLowerCase()) ? "Please enter a valid email address." : null }));
          break;
        case "password":
          setErrors((prev) => ({ ...prev, [name]: value.length < 8 ? "Password should be Min 8 characters." : null }));
          setErrors((prev) => ({ ...prev, confirmPassword: formData.confirmPassword !== value ? "Not matched with a password." : null }));
          break;
        case "confirmPassword":
          setErrors((prev) => ({ ...prev, [name]: formData.password !== value ? "Not matched with a password." : null }));
          break;
        default:
          setErrors((prev) => ({ ...prev, [name]: null }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = {};
    const requiredFields = ["fname", "lname", "userType", "email", "password", "confirmPassword", "state", "cities"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        validationErrors[field] = `${getFieldLabel(field)} is required`;
      }
    });

    if (!selectedCities.length) {
      validationErrors.cities = "City is required";
    }

    if (formData.email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!re.test(String(formData.email).toLowerCase())) validationErrors.email = "Please enter a valid email address.";
    }

    if (formData.password && formData.password.length < 8) {
      validationErrors.password = "Password should be Min 8 characters.";
    }

    if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Not matched with a password.";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      await api.post("/user", { email: formData.email.toLowerCase(), ...formData });
      navigate("/user");
    } catch (error) {
      setErrorMessage((error.response && error.response.data.response) || "An error occurred while saving the user.");
      setErrorModalOpen(true);
    }
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  const getFieldLabel = (fieldName) => {
    switch (fieldName) {
      case "fname":
        return "First Name";
      case "lname":
        return "Last Name";
      case "userType":
        return "User Type";
      case "confirmPassword":
        return "Confirm Password";
      case "email":
        return "Email";
      case "password":
        return "Password";
      case "state":
        return "State";
      case "cities":
        return "Cities";
      default:
        return fieldName;
    }
  };

  return (
    <div className="bg-emerald-50 min-h-screen flex flex-col">
      <div className="container mx-auto px-4 py-8 bg-emerald-50">
        {errorModalOpen && <ErrorModal message={errorMessage} onClose={closeErrorModal} />}
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-8 mx-4 md:mx-16">
          <h2 className="text-2xl font-bold mb-4 text-center">ADD USER</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label htmlFor="fname" className="block text-sm font-medium text-gray-700">
                First Name *
              </label>
              <input
                type="text"
                id="fname"
                name="fname"
                onBlur={validate}
                value={formData.fname}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:border-indigo-500 sm:text-sm ${errors.fname ? "border-red-500" : ""}`}
              />
              {errors.fname && <p className="text-red-500 text-sm mt-1">{errors.fname}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="lname" className="block text-sm font-medium text-gray-700">
                Last Name *
              </label>
              <input
                type="text"
                id="lname"
                name="lname"
                onBlur={validate}
                value={formData.lname}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.lname ? "border-red-500" : ""}`}
              />
              {errors.lname && <p className="text-red-500 text-sm mt-1">{errors.lname}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="userType" className="block text-sm font-medium text-gray-700">
                User Type *
              </label>
              <select
                id="userType"
                name="userType"
                onBlur={validate}
                value={formData.userType}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.userType ? "border-red-500" : ""}`}>
                <option value="" disabled>
                  Select User Type
                </option>
                <option value="admin">Admin</option>
                <option value="collector">Collector</option>
              </select>
              {errors.userType && <p className="text-red-500 text-sm mt-1">{errors.userType}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email *
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onBlur={validate}
                value={formData.email}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.email ? "border-red-500" : ""}`}
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password *
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onBlur={validate}
                value={formData.password}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.password ? "border-red-500" : ""}`}
              />
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password *
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                onBlur={validate}
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`mt-1 block w-full border border-gray-500 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
              />
              {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                State *
              </label>
              <Select
                id="state"
                options={stateOptions}
                onChange={handleStateChange}
                value={stateOptions.find((option) => option.value === formData.state)}
                className={`basic-single mt-1 border border-gray-500 rounded-md ${errors.state ? "border-red-500" : ""}`}
                placeholder="Select State"
              />
              {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="cities" className="block text-sm font-medium text-gray-700">
                Cities *
              </label>
              <MultiSelect
                id="cities"
                options={cityOptions}
                value={selectedCities}
                onChange={handleCityChange}
                labelledBy="Select Cities"
                disabled={!formData.state}
                className={`basic-multi-select mt-1 border border-gray-500 rounded-md shadow-sm ${errors.cities ? "border-red-500" : ""}  ${formData.state ? "" : "cursor-not-allowed opacity-50"}`}
              />
              {errors.cities && <p className="text-red-500 text-sm mt-1">{errors.cities}</p>}
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type="submit"
              className={`px-4 py-2 rounded-md text-white ${!Object.values(errors).some((e) => e) && isSaveEnabled ? "bg-color " : "bg-gray-400 cursor-not-allowed"}`}
              disabled={Object.values(errors).some((e) => e) || !isSaveEnabled}>
              Save
            </button>
            <button type="button" onClick={() => navigate("/user")} className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition-colors duration-300">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserForm;
