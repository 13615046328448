// src/navigation.js
import { useNavigate } from 'react-router-dom';

let navigateInstance;

export const NavigateSetter = () => {
  navigateInstance = useNavigate();
  return null;
};

export const redirectTo = (path) => {
  if (navigateInstance) {
    navigateInstance(path);
  } else {
    console.error("Navigate instance is not set");
  }
};
