import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/authContext";
import Header from "./Header";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const token = localStorage.getItem("accessToken");

  return isAuthenticated || token ? (
    <>
      <Header /> <Component {...rest} />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
