import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

const UserPage = () => {
  const [filters, setFilters] = useState("");
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [expandedDetailsIndex, setExpandedDetailsIndex] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    api
      .get("/user/getAll")
      .then(({ data }) => {
        const { response } = data;
        setUserData(response);
        setFilteredData(response);
        setCurrentPage(1);
        setExpandedDetailsIndex(-1);
        const pages = Math.ceil(response.length / itemsPerPage);
        setTotalPages(pages);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      });
  };

  useEffect(() => {
    const pages = Math.ceil(filteredData.length / itemsPerPage);
    setTotalPages(pages);
  }, [filteredData, itemsPerPage]);

  const handleFilterChange = (value) => {
    setFilters(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const filtered = userData.filter((user) => {
      const fname = user.fname || "";
      const lname = user.lname || "";
      const email = user.email || "";
      const cities = user.cities ? user.cities.join(" ") : "";
      const userType = user.userType || "";

      return (
        fname.toLowerCase().includes(filters.toLowerCase()) ||
        lname.toLowerCase().includes(filters.toLowerCase()) ||
        email.toLowerCase().includes(filters.toLowerCase()) ||
        cities.toLowerCase().includes(filters.toLowerCase()) ||
        userType.toLowerCase().includes(filters.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setFilters("");
    setFilteredData(userData);
  };

  const handleToggleDetails = (index) => {
    setExpandedDetailsIndex(index === expandedDetailsIndex ? -1 : index);
  };

  const handleEdit = (id) => {
    navigate(`/edit-user/${id}`);
  };

  const handleDelete = (id) => {
    api
      .delete(`/user/remove/${id}`)
      .then((response) => {
        fetchData();
        setExpandedDetailsIndex(-1);
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginationButtons = [];
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, totalPages); i++) {
    paginationButtons.push(
      <button key={i} onClick={() => handlePageChange(i)} className={`bg-color text-white px-4 py-2 rounded-md mr-2 ${i === currentPage ? "bg-color-dark" : "bg-color-dark-hover"}`}>
        {i}
      </button>
    );
  }

  return (
    <div className="bg-emerald-50 w-full mx-auto min-h-screen flex flex-col">
      <div className="container w-full mx-auto px-4 py-8">
        <div className="filter-section bg-white rounded-lg shadow-md mb-8 p-4">
          <form onSubmit={handleSubmit} className="flex items-center justify-center">
            <label htmlFor="globalFilter" className="block text-sm font-medium text-gray-700 mr-2">
              Search:
            </label>
            <input
              type="text"
              id="globalFilter"
              placeholder="Enter search text"
              value={filters}
              onChange={(e) => handleFilterChange(e.target.value)}
              className="border border-gray-300 py-2 px-3 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="submit"
              className={`bg-sky-500 text-white px-4 py-2 rounded-md ml-2 bg-color-hover transition-colors duration-300  ${!filters ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={!filters}>
              Search
            </button>
            <button
              type="button"
              onClick={handleClear}
              className={`bg-gray-500 text-white px-4 py-2 rounded-md ml-2 hover:bg-gray-600 transition-colors duration-300   ${!filters ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={!filters}>
              Clear
            </button>
          </form>
        </div>

        <div className="flex justify-end mt-4">
          <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors duration-300" onClick={() => navigate("/add-user")}>
            Add User
          </button>
        </div>

        <div className="data-table-section bg-white rounded-lg shadow-md p-4 mt-4">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="py-2">First Name</th>
                <th className="py-2">Last Name</th>
                <th className="py-2">User Type</th>
                <th className="py-2">Email</th>
                <th className="py-2">City</th>
                <th className="py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((user, index) => (
                <React.Fragment key={user.id}>
                  <tr className="text-center border-b-2">
                    <td className="py-4">{user.fname || "N/A"}</td>
                    <td className="py-4">{user.lname || "N/A"}</td>
                    <td className="py-4">{user.userType || "N/A"}</td>
                    <td className="py-4">{user.email || "N/A"}</td>
                    <td className="py-4">{user.cities ? user.cities.join(", ") : "N/A"}</td>
                    <td className="py-4 relative">
                      <FontAwesomeIcon icon={faPencilAlt} onClick={() => handleEdit(user.id)} className="cursor-pointer text-sky-500 mr-2" />
                      <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(user.id)} className="cursor-pointer text-red-500" />
                    </td>
                    <td className="py-4">
                      <span onClick={() => handleToggleDetails(index)} className="absolute transform translate-y-1/2 left-1/2 -ml-4 cursor-pointer" style={{ marginTop: "3px" }}>
                        <FontAwesomeIcon icon={expandedDetailsIndex === index ? faChevronUp : faChevronDown} />
                      </span>
                    </td>
                  </tr>

                  {expandedDetailsIndex === index && (
                    <tr>
                      <td colSpan="6" className="border-b border-gray-300 px-4 py-2 bg-slate-100">
                        <div className="mt-2">
                          <p>
                            <span className="font-bold">First Name:</span> {user.fname}
                          </p>
                          <p>
                            <span className="font-bold">Last Name:</span> {user.lname}
                          </p>
                          <p>
                            <span className="font-bold">Usertype:</span> {user.userType}
                          </p>
                          <p>
                            <span className="font-bold">Email:</span> {user.email}
                          </p>
                          <p>
                            <span className="font-bold">City:</span> {user.cities.join(" , ")}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between items-center mt-4">
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">{paginationButtons}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
